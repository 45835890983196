<template>
  <div>
    <div
        class="list-item"
        v-for="(item, index) in waitCustomerList"
        :key="index"
        
      >
        <div style="flex: 1;width: 0;">
          <div class="list-item-header">
            <span class="customer-name">{{
              item.customerName || item.name
            }}</span>
            <span class="time">{{ item.createAt | formatTime('{y}-{m}-{d} {h}:{i}:{s}') }}</span>
          </div>
          <div class="list-item-footer">
            {{ changeContent(item.content,item.contentType) }}
          </div>
        </div>
        <div class="list-btn" @click="clickCustomer(item)">
          接入
        </div>
      </div>
      <el-empty v-if="waitCustomerList.length == 0" description="暂无待接入客户"></el-empty>
      <el-dialog
        :visible.sync="dialogVisible"
        :show-close="false"
        width="50%"
        top="45vh"
      >
        <div class="err-msg">{{ msg }}</div>
      </el-dialog>
  </div>
</template>

<script>
import {
  getContent2,
} from "@/components/serviceIm/index";
import { setRemBaseFontSize } from "@/utils/common";
import request from '@/utils/request'
import { Message } from 'element-ui'
export default {
  created() {
    document.title = '待接入列表'
    this.setRemBaseFontSize();
    // 监听窗口大小变化事件，以更新基准字体大小
    window.addEventListener("resize", this.setRemBaseFontSize);
    this.app = this.$route.query.app;
    this.serviceCode = this.$route.query.serviceCode;
    this.serviceName = this.$route.query.serviceName;
    this.sessionId = this.$route.query.sessionId
    this.getContent2()
  },
  data() {
    return {
      waitCustomerList: [],
      app:'',
      serviceCode:'',
      serviceName: '',
      sessionId: '',
      dialogVisible: false,
      msg: ''
    }
  },
  methods:{
    changeContent(content,type) {
      if(type == 'word') {
        return content
      }else if(type == 'vedio') {
        return '[视频]'
      }else if(type == 'audio') {
        return '[音频]'
      }else if(type == 'img') {
        return '[图片]'
      }else if(type == 'file') {
        return '[附件]'
      }else if(type == 'url') {
        return '[超链接]'
      }
    },
    getContent2,
    setRemBaseFontSize,
    clickCustomer(item) {
      this.connectCustomers(item.roomId,item.customerCode,item.customerName);
    },
    connectCustomers(roomId,customerCode,customerName) {
      this.roomId = roomId;
      const form = {
        roomId,
        serviceCode: this.serviceCode,
        serviceName: this.serviceName,
        sessionId: this.sessionId,
        app: this.app,
        customerCode:customerCode
      };
      request.post("/api/ws/connectCustomer", form).then(() => {
        this.$router.push(`/phoneServiceIm?roomId=${roomId}&app=${this.app}&customerCode=${customerCode}&serviceCode=${this.serviceCode}&name=${customerName}`)
      }).catch(err => {
        Message.closeAll()
        this.msg = err.msg
        this.dialogVisible = true
        setTimeout(() => {
          this.dialogVisible = false
        }, 1500);
        this.getContent2()
        
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  font-size: 0.18rem;
  width: calc(100% - 0.28rem);
  padding: 0.12rem 0;
  margin: 0 0.12rem;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  .list-btn {
    margin-left: 0.2rem;
    background: green;
    color: #fff;
    padding:0.08rem 0.2rem;
    border-radius: 0.05rem;
  }
  &-header {
    color: #898989;
    margin-bottom: 0.05rem;
    display: flex;
    align-items: center;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .time {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .wait-title {
    font-weight: bold;
    color: #333;
  }
  .customer-name {
    color: #333;
    margin-right: 0.1rem;
  }
  &-footer {
    color: #898989;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .no-read {
    color: #fff;
    text-align: center;
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    margin-left: 0.1rem;
    background-color: red;
    display: inline-block;
  }
  
}
.err-msg {
  text-align: center;
}
::v-deep .el-dialog__body {
  background-color: #FEF0F0;
  color: #F56C6C;
}
::v-deep .el-dialog__header {
  padding: 0 !important;
}
</style>